<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="match_type"
                :name="$t('labels.match_type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.match_type')"
                >
                  <v-select
                    v-model="form.match_type"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="match_types"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.match_type')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.match_type === 'league'"
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="league_id"
                :name="$t('labels.select_league')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.select_league')"
                >
                  <v-select
                    v-model="form.league_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_label"
                    :options="leagues"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.select_league')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.match_type === 'championship'"
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="championship_id"
                :name="$t('labels.select_championship')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.select_championship')"
                >
                  <v-select
                    v-model="form.championship_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="championships"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.select_championship')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="home_team_id"
                :name="$t('labels.home_team')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.home_team')"
                >
                  <v-select
                    v-model="form.home_team_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="teams"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.home_team')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="away_team_id"
                :name="$t('labels.away_team')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.away_team')"
                >
                  <v-select
                    v-model="form.away_team_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="teams"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.away_team')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>
            <b-col
              v-if="home_players.length"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="home_lineup_players"
                :name="$t('labels.home_lineup_players')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.home_lineup_players')"
                >
                  <v-select
                    v-model="form.home_lineup_players"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="home_players"
                    multiple
                    :reduce="item => item.id"
                    :placeholder="$t('labels.home_lineup_players')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="away_players.length"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="away_lineup_players"
                :name="$t('labels.away_lineup_players')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.away_lineup_players')"
                >
                  <v-select
                    v-model="form.away_lineup_players"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="away_players"
                    multiple
                    :reduce="item => item.id"
                    :placeholder="$t('labels.away_lineup_players')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="home_players.length"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="home_bench_players"
                :name="$t('labels.home_bench_players')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.home_bench_players')"
                >
                  <v-select
                    v-model="form.home_bench_players"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="home_players"
                    multiple
                    :reduce="item => item.id"
                    :placeholder="$t('labels.home_bench_players')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="away_players.length"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="away_bench_players"
                :name="$t('labels.away_bench_players')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.away_bench_players')"
                >
                  <v-select
                    v-model="form.away_bench_players"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="away_players"
                    multiple
                    :reduce="item => item.id"
                    :placeholder="$t('labels.away_bench_players')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="home_formation_id"
                :name="$t('labels.home_formation')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.home_formation')"
                >
                  <v-select
                    v-model="form.home_formation_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_formation_label"
                    :options="formations"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.home_formation')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="away_formation_id"
                :name="$t('labels.away_formation')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.away_formation')"
                >
                  <v-select
                    v-model="form.away_formation_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_formation_label"
                    :options="formations"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.away_formation')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.home_goals')"
                vid="home_goals"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.home_goals')"
                >
                  <b-form-input
                    v-model="form.home_goals"
                    :placeholder="$t('labels.home_goals')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.away_goals')"
                vid="away_goals"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.away_goals')"
                >
                  <b-form-input
                    v-model="form.away_goals"
                    :placeholder="$t('labels.away_goals')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.start_at')"
                vid="start_at"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.start_at')"
                >
                  <flat-pickr
                    v-model="form.start_at"
                    class="form-control"
                    :config="config"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="stadium_id"
                :name="$t('labels.stadium')"
              >
                <b-form-group
                  :label="$t('labels.stadium')"
                >
                  <v-select
                    v-model="form.stadium_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_label"
                    :options="stadiums"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.stadium')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="observation"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`observation.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.observation'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.observation'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.observation[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.observation'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import formMixin from '@/mixins/formMixin'

export default {
  components: {
    flatPickr,
  },
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      teams: [],
      stadiums: [],
      match_types: [],
      leagues: [],
      championships: [],
      formations: [],
      home_players: [],
      away_players: [],
      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      form: {
        home_team_id: null,
        away_team_id: null,
        away_formation_id: null,
        home_formation_id: null,
        start_at: null,
        category_id: null,
        stadium_id: null,
        home_goals: null,
        away_goals: null,
        match_type: null,
        league_id: null,
        championship_id: null,
        home_bench_players: null,
        home_lineup_players: null,
        away_bench_players: null,
        away_lineup_players: null,
        observation: {
          en: null,
          ar: null,
        },
        status: 'active',
      },
    }
  },
  watch: {
    'form.league_id': function (val) {
      this.getTeams(`?league_id=${val}`)
    },
    'form.championship_id': function (val) {
      this.getTeams(`?championship_id=${val}`)
    },
    'form.home_team_id': function (val) {
      this.getHomePlayers(`?team_id=${val}`)
    },
    'form.away_team_id': function (val) {
      this.getAwayPlayers(`?team_id=${val}`)
    },
  },
  created() {
    this.getStadiums()
    this.getMatchTypes()
    this.getLeagues()
    this.getChampionships()
    this.getFormations()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getTeams(id) {
      this.axios.get(`/lists/Team${id}`)
        .then(res => {
          this.teams = res.data
        })
    },
    getStadiums() {
      this.axios.get('/lists/Stadium')
        .then(res => {
          this.stadiums = res.data
        })
    },
    getMatchTypes() {
      this.axios.get('/lists/match/types')
        .then(res => {
          this.match_types = res.data
        })
    },
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },
    getChampionships() {
      this.axios.get('/lists/Championship')
        .then(res => {
          this.championships = res.data
        })
    },
    getFormations() {
      this.axios.get('/lists/Formation')
        .then(res => {
          this.formations = res.data
        })
    },
    getHomePlayers(val) {
      this.axios.get(`/lists/team/players${val}`)
        .then(res => {
          this.home_players = res.data
        })
    },
    getAwayPlayers(val) {
      this.axios.get(`/lists/team/players${val}`)
        .then(res => {
          this.away_players = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            home_team_id: data.home_team_id,
            away_team_id: data.away_team_id,
            start_at: data.start_at,
            category_id: data.category_id,
            stadium_id: data.stadium_id,
            home_goals: data.home_goals,
            away_goals: data.away_goals,
            match_type: data.match_type,
            league_id: data.league_id,
            championship_id: data.championship_id,
            // observation: data.observation,
            away_formation_id: data.away_formation_id,
            home_formation_id: data.home_formation_id,
            home_bench_players: data.home_bench_players.map(item => item.id),
            home_lineup_players: data.home_lineup_players.map(item => item.id),
            away_bench_players: data.away_bench_players.map(item => item.id),
            away_lineup_players: data.away_lineup_players.map(item => item.id),
            status: data.status,
          }
          this.form.observation = {
            ar: null,
            en: null,
          }
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
  },

}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
