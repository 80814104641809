var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"match_type","name":_vm.$t('labels.match_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.match_type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"label","options":_vm.match_types,"reduce":function (item) { return item.value; },"placeholder":_vm.$t('labels.match_type')},model:{value:(_vm.form.match_type),callback:function ($$v) {_vm.$set(_vm.form, "match_type", $$v)},expression:"form.match_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.match_type === 'league')?_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"league_id","name":_vm.$t('labels.select_league'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.select_league')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"title_label","options":_vm.leagues,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.select_league')},model:{value:(_vm.form.league_id),callback:function ($$v) {_vm.$set(_vm.form, "league_id", $$v)},expression:"form.league_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3651697869)})],1):_vm._e(),(_vm.form.match_type === 'championship')?_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"championship_id","name":_vm.$t('labels.select_championship'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.select_championship')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.championships,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.select_championship')},model:{value:(_vm.form.championship_id),callback:function ($$v) {_vm.$set(_vm.form, "championship_id", $$v)},expression:"form.championship_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1572774218)})],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"home_team_id","name":_vm.$t('labels.home_team'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.home_team')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.teams,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.home_team')},model:{value:(_vm.form.home_team_id),callback:function ($$v) {_vm.$set(_vm.form, "home_team_id", $$v)},expression:"form.home_team_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"away_team_id","name":_vm.$t('labels.away_team'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.away_team')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.teams,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.away_team')},model:{value:(_vm.form.away_team_id),callback:function ($$v) {_vm.$set(_vm.form, "away_team_id", $$v)},expression:"form.away_team_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[(_vm.home_players.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"home_lineup_players","name":_vm.$t('labels.home_lineup_players'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.home_lineup_players')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.home_players,"multiple":"","reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.home_lineup_players')},model:{value:(_vm.form.home_lineup_players),callback:function ($$v) {_vm.$set(_vm.form, "home_lineup_players", $$v)},expression:"form.home_lineup_players"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,221733625)})],1):_vm._e(),(_vm.away_players.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"away_lineup_players","name":_vm.$t('labels.away_lineup_players'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.away_lineup_players')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.away_players,"multiple":"","reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.away_lineup_players')},model:{value:(_vm.form.away_lineup_players),callback:function ($$v) {_vm.$set(_vm.form, "away_lineup_players", $$v)},expression:"form.away_lineup_players"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3309624473)})],1):_vm._e()],1),_c('b-row',[(_vm.home_players.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"home_bench_players","name":_vm.$t('labels.home_bench_players'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.home_bench_players')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.home_players,"multiple":"","reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.home_bench_players')},model:{value:(_vm.form.home_bench_players),callback:function ($$v) {_vm.$set(_vm.form, "home_bench_players", $$v)},expression:"form.home_bench_players"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,291662928)})],1):_vm._e(),(_vm.away_players.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"away_bench_players","name":_vm.$t('labels.away_bench_players'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.away_bench_players')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.away_players,"multiple":"","reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.away_bench_players')},model:{value:(_vm.form.away_bench_players),callback:function ($$v) {_vm.$set(_vm.form, "away_bench_players", $$v)},expression:"form.away_bench_players"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2157236432)})],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"home_formation_id","name":_vm.$t('labels.home_formation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.home_formation')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"title_formation_label","options":_vm.formations,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.home_formation')},model:{value:(_vm.form.home_formation_id),callback:function ($$v) {_vm.$set(_vm.form, "home_formation_id", $$v)},expression:"form.home_formation_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"away_formation_id","name":_vm.$t('labels.away_formation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.away_formation')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"title_formation_label","options":_vm.formations,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.away_formation')},model:{value:(_vm.form.away_formation_id),callback:function ($$v) {_vm.$set(_vm.form, "away_formation_id", $$v)},expression:"form.away_formation_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('labels.home_goals'),"vid":"home_goals","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.home_goals')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('labels.home_goals'),"autocomplete":"off"},model:{value:(_vm.form.home_goals),callback:function ($$v) {_vm.$set(_vm.form, "home_goals", $$v)},expression:"form.home_goals"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('labels.away_goals'),"vid":"away_goals","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.away_goals')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('labels.away_goals'),"autocomplete":"off"},model:{value:(_vm.form.away_goals),callback:function ($$v) {_vm.$set(_vm.form, "away_goals", $$v)},expression:"form.away_goals"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('labels.start_at'),"vid":"start_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.start_at')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config},model:{value:(_vm.form.start_at),callback:function ($$v) {_vm.$set(_vm.form, "start_at", $$v)},expression:"form.start_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"stadium_id","name":_vm.$t('labels.stadium')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.stadium')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"title_label","options":_vm.stadiums,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.stadium')},model:{value:(_vm.form.stadium_id),callback:function ($$v) {_vm.$set(_vm.form, "stadium_id", $$v)},expression:"form.stadium_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"observation"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("observation." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.observation'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.observation'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.observation'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rows":"4"},model:{value:(_vm.form.observation[k]),callback:function ($$v) {_vm.$set(_vm.form.observation, k, $$v)},expression:"form.observation[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"active","unchecked-value":"inactive","name":"check-button","switch":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }